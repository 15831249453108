import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  // BASE_URL: ` http://localhost:3000/api/`,

  url = 'https://jolly-moving-turkey.ngrok-free.app/api';
  // url = 'http://ec2-18-212-126-124.compute-1.amazonaws.com/api'
  // url = 'https://gkow0o0.srv489678.hstgr.cloud/api';
  // url = 'https://painel.dev.legado.sim5.com.br/api';
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private cookie: CookieService,
    private router: Router
  ) {
    const toArray = this.document.location.hostname.split('.');

    console.log('URL break', toArray[0], toArray[1]);

    // this.url = 'https://api.qas-metodista.oficina5.com.br/api';

    console.log(toArray[0], toArray[1]);

    // if (toArray[0] === 'd14ymh4xorvnq3') {
    //   this.url = 'https://api.qas-metodista.oficina5.com.br/api';
    // }
    // if (toArray[0] === 'gkow0o0') {
    //   this.url = 'https://gkow0o0.srv489678.hstgr.cloud/api';
    // }
    // if (toArray[0] === 'sim5' || toArray[1] === 'sim5') {
    //   this.url = '//34.236.193.48:3000/api';
    // }
    // //
    // if (toArray[0] === 'https://painel') {
    //   this.url = 'https://gkow0o0.srv489678.hstgr.cloud/api';
    // }

    // if (toArray[0] === 'painel') {
    //   this.url = 'https://gkow0o0.srv489678.hstgr.cloud/api';
    // }

    // if (toArray[0] === 'dev-painel') {
    //   this.url = 'https://dev-server.sim5.com.br/api';
    // }

    // if (toArray[0] === 'prod-painel') {
    //   this.url = 'https://prod-server.sim5.com.br/api';
    // }

    console.log('===> URL API', this.url);
  }

  public getUser() {
    /* if(localStorage.getItem('user')){
            var bytes = CryptoJS.AES.decrypt(localStorage.getItem('user'), 'Igreja Metodista 5ª Região');
            var user = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            return user;
        } */

    return JSON.parse(localStorage.getItem('user'));
  }

  public getToken() {
    if (this.cookie.get('token')) {
      return this.cookie.get('token');
    }
    return null;
  }

  setToken(token) {
    let expiredDate = new Date();
    expiredDate.setDate(expiredDate.getDate() + 30);

    if (this.getUser()) {
      this.cookie.put('token', token, { expires: expiredDate });
      //this.cookie.put('token', token, {expires: expiredDate, secure: true, httpOnly: true});
    }
  }

  public setUser(user) {
    /* var userCrypto = CryptoJS.AES.encrypt(JSON.stringify(user), 'Igreja Metodista 5ª Região').toString();
        localStorage.setItem('user', userCrypto); */

    localStorage.setItem('user', JSON.stringify(user));
  }

  public getIgreja() {
    if (this.getUser()) {
      return this.getUser().IDG004;
    }
    return null;
  }

  public getGrupo() {
    return this.getUser().IDG031;
  }

  public clear() {
    localStorage.clear();
    this.cookie.removeAll();
  }
}
